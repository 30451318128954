/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300&family=Nunito+Sans:wght@200&family=Poppins:wght@200;300&display=swap'); */
body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-family: 'Nunito Sans', sans-serif;
  font-family: 'Poppins', sans-serif;  
}

.center{
  justify-content: center !important;
  align-items: center !important;
  display: flex !important;
}

.flex{
  display: flex !important;
}

::-webkit-scrollbar {
  display: block;
  width: 5px;
}
::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #c0c0c0;
  border-right: none;
  border-left: none;
  border-radius: 10px;
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
}