@media screen and (max-width: 750px) {
    .auth-forms{
      width: 70% !important;
    }
    @media screen and (max-width: 550px) {
        .auth-forms{
            width: 78% !important;
        }
    }

    @media screen and (max-width: 450px) {
        .auth-forms{
            width: 85% !important;
        }
    }

    @media screen and (max-width: 400px) {
        .auth-forms{
            width: 97% !important;
        }
    }
}

.pg-containers{
    /* background-image: url('../../assets/images/common.png'); */
    background-repeat: no-repeat;
    background-size: cover;
}

.custom-btn{
    padding: 1px !important;
    font-weight: bold !important;
    position: relative;
    left: 3px;
}

.custom-btn:hover{
    background-color: #ffff !important;
    color: rgb(227, 13, 13);
}

.card_service{
    transition: 0.5s;
    cursor: pointer;
}

.card_service:hover{
transform: scale(1.05);
}