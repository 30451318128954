.text-center{
    text-align: center !important;
  }
  .pagButton>a{
    padding: 5px 14px !important;
  }
  
  .pagButton-border{
    border: 1px solid rgb(215, 215, 215);
  }
  
  .nextBtn{
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  
  .prevBtn{
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  
  
  .pag-container{
    margin: auto !important;
  }
  
  .showing-page{
    padding-left: 40px;
    font-size: 15px;
  }
  
  .pagContainer{
    align-items: center;
    /* justify-content: center; */
    padding-top: 20px;
    padding-bottom: 20px;
    gap: 100px;
  }
  
  .pagButton:hover{
    background-color: var(--primary-color-light);
  }
  
  .pagButton-border-active{
    background-color: #0e54dc;
    color: white !important;
    border: 1px solid #0e54dc !important;
  }
  
  .pagButton-border-active:hover{
    background-color: #0e54dc !important;
  }
  
  .pag-buttons{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .pg-tab{
    width: 100% !important;
    text-align: center;
  }
  
  .pag-buttons{
    min-width: 60% !important;
  }
  
  .rdrStartEdge, .rdrEndEdge{
    background-color: var(--primary-color) !important;
  }
  
  .rdrYearPicker>select{
    font-size: 14px !important;
    font-family: arial !important;
    font-style: normal !important;
    background-color: white !important;
  }
  
  .rdrMonthPicker>select{
    font-size: 14px !important;
    font-family: arial !important;
    background-color: white !important;
  }
  
  .mr-0{
    margin-right: 0px !important;
  }
  
  .pr-0{
    padding-right: 0px !important;
  }
  
  .search-input_nopad{
    position: relative;
    left: 20px !important;
  }
  
  .menuListContainer_nopad>.menu-list{
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    margin-bottom: 2px !important;
   font-size: 15px !important;
   }
  
   .font-semibold{
    font-weight: 550;
   }
  
   .product-desc{
    gap: 7px !important;
   }
  
   .text-active{
    color: rgb(10, 186, 10) !important;
    font-weight: 550;
   }
  
   .border-left{
    background-color: #bcc5D1;
    height: 17px;
    position: relative;
    top: 3.5px;
    border-left: 2px solid #bcc5D1 !important;
   }
  
   .cancelTimes:hover{
    color: #f52323 !important;
   }
  
   .refundBtn{
    color: #788698;
   }
  
   .refundBtn:hover{
    color: #ffc939;
   }
  
   .product-footer{
    column-gap: 7px !important;
    flex-wrap: wrap !important;
   }
  
   .border-gray{
    background-color: var(--text-color);
   }
  
   .text-gray{
    color: var(--text-color) !important;
   }
  
   .product_cont{
    width: 100%;
    margin-bottom: 10px;
    padding: 20px;
    border: 1px solid #e2e1e1 !important;
    border-radius: 7px;
   }
  
   .product_cont:hover{
    border: 1px solid #2B6FF2 !important;
   }
  
   .modal-body-cont{
    overflow-y: auto !important;
    max-height: 80vh !important;
   }
  
   @media screen and (max-width: 1600px) {
    .modal-body-cont{
      max-height: 76vh !important;
     }
   }
  
   @media screen and (max-width: 1450px) {
    .modal-body-cont{
      max-height: 70vh !important;
     }
   }
  
   .modal-pad2{
    padding: 5px !important;
   }
  
   .font-semibold-sm{
    font-weight: 500 !important;
   }
  
   .product-desc{
    width: 100%;
   }
  
   .product-footer{
    width: 100%;
   }
  
   .product_sub_cont{
    width: 100%;
   }
  
   .modal-sub-header{
    font-size: 16px !important;
    margin-bottom: 10px;
    font-weight: 500;
    margin-top: 7px;
   }
  
   .refund_cont{
    padding-top: 12px !important;
    padding-bottom: 12px !important;
   }
  
   .confirm-btns{
    padding: 2px 20px 2px 20px !important;
   }
  
   .bg-danger{
    background-color: var(--danger-color)  !important;
   }
  
   .bg-danger:hover{
    background-color: #ec0f25 !important;
   }
  
   .text-white{
    color: white !important;
   }
  
   .py-20{
    padding-top: 70px !important;
    padding-bottom: 70px !important;
   }
  
   .text-capitalize{
  text-transform: capitalize !important;
   }
  
   .text-uppercase{
    text-transform: uppercase !important;
   }
  
   .refunded{
    color: #16db85 !important;
   }
  
   .justify-end{
    justify-content: flex-end !important;
   }
  
   .items-end{
    align-items: flex-end;
   }
  
   .w-full{
    width: 100% !important;
   }
  
   .text-right{
    text-align: right !important;
   }
  
   .main-btn{
    background-color: #0e54dc !important;
    color: white !important;
   }
  
   .centered-component{
    padding: 60px 0px 75px 0px !important;
    justify-content: center;
    text-align: center;
   }
  
   .text-base{
     font-size: 15px;
   }

   .inline-flex{
    display: inline-flex !important;
   }